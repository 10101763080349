// Spain
export const locale = {
  TRANSLATOR: {
    SELECT: "Elige tu idioma.",
  },
  MENU: {
    ACTIONS: "Comportamiento",
    CREATE_POST: "Crear nueva publicación",
    PAGES: "Páginas",
    FEATURES: "Caracteristicas",
    APPS: "Aplicaciones",
    HOME: "Inicio",
    SECURITY: "Seguridad",
    SECURITY_INCIDENTS: "Incidentes de seguridad",
    TWOFA: "2FA",
    USERS: "Usuarios",
    MIGRATIONS: "Migrations",
    DOMAINS: "Dominios",
    COMPLIANCE: "Compliance",
    ALIASES: "Alias",
    LISTS: "Listas",
    FILTERING: "Filtrado",
    WHITELIST: "Lista blanca",
    BLACKLIST: "Lista negra",
    AUTO_WHITELIST: "Lista blanca automática",
    ADMIN_IPACCESS_LISTS: "Listas de acceso de IP de Admin",
    IPACCESS_LISTS: "Listas de acceso de IP",
    IPTRUSTED: "IPs de confianza del Usuario",
    BRANDING: "Personalización de marca",
    SETTINGS: "Ajustes",
    LOGS: "Registros",
    INCOMING_LOGS: "Registros entrantes",
    OUTGOING_LOGS: "Registros salientes",
    LAST_LOGINS: "Últimos inicios de sesión",
    ALIAS_DOMAINS: "Dominios de alias",
    SMARTLISTS: "Listas inteligentes",
    SUBADMINS: "Sub-Admins",
  },
  COMMON: {
    ACTION: "Acción",
    ADMIN: "Admin",
    DATE: "Fecha",
    DAYS_ARRAY: {
      1: "Lunes",
      2: "Martes",
      3: "Miércoles",
      4: "Jueves",
      5: "Viernes",
      6: "Sábado",
      7: "Domingo",
    },
    RPERPAGE: "Fila por página",
    ENABLED: "Habilitado",
    DISABLED: "Deshabilitado",
    SAVE: "Guardar",
    STEP: "Paso",
    CANCEL: "Cancelar",
    CLOSE: "Cerca",
    RESET: "Reiniciar",
    NEXT: "Siguiente",
    SEL_DOMAIN: "Seleccione el dominio",
    SEL_USER: "Select User",
    SEARCH: "Buscar",
    USERNAME: "Nombre de usuario",
    USER_NAME: "Nombre de usuario",
    DOMAIN: "Dominio",
    TYPE: "Tipo",
    RANGE: "Máscara de red",
    PASSWORD: "Contraseña",
    QUOTA: "Cuota(GB)",
    NEW: "Nuevo",
    COPY: "Copiar",
    COPY_CLIPBOARD: "Copiar al portapapeles",
    ADD: "Añadir",
    LANGUAGE: "Idioma",
    LOCALIZATION: "Localización",
    DATE_FORMAT: "Formato de fecha",
    UPDATE: "Actualizar",
    DEL_WARNING:
      "¡Todos los datos se perderán! ¿Estás seguro de que desea eliminar?",
    DEL_ITEM_WARNING:
      "¡Todos los datos se perderán! Estás seguro de que desea eliminar {item}?",
    DEL_CONFIRM: "Eliminar confirmación",
    HOME_COUNTRY: "País de origen",
    TRAVEL_MODE: "Modo de viaje",
    TIMEZONE: "zona horaria",
    ACTIVE: "Activo",
    INACTIVE: "Inactiva",
    YES: "Sí",
    NO: "No",
    NO_DATA: "Datos no disponibles",
    ADDRESS: "Dirección",
    CHECK_SPAM: "Revisar Correo no deseado",
    CHECK_VIRUS: "Revisar Virus",
    BROWSE: "Vistazo",
    DESCRIPTION: "Descripción",
    DELETE: "Eliminar",
    EDIT: "Editar",
    NOT_ALLOWED: "No permitido",
    ALLOWED: "Permitido",
    DAY: "día",
    DAYS: "dias",
    DESTINATION: "Destination",
    VALUE: "Valor",
    FOOTER: "Pie de página",
    MORE_DETAILS: "More details",
    OPTIONAL: "Optional",
    OTPASSWORD: "OTPassword",
    OTHER_ADMIN: "Otro administrador",
    SEARCH_OPTIONS: "Opciones de búsqueda",
    SEARCH_RESULTS: "Resultados de búsqueda",
    SOURCE: "Source",
    STATUS: "Estado",
    ADMIN_PANEL: "Panel de administrador",
    SUCCESS: "Éxito",
    ERROR: "Error",
    SUCCESS_ALERT: "La operación fue exitosa!",
    ERROR_ALERT: "La operación ha fallado",
    WARNING: "Advertencia",
    OK: "Ok",
    HI: "Hola",
    TAB_LOGO: "Panel de Administración",
    AUTO_LOGOUT: "Cierre de sesión automático",
    ALL_DOMAINS: "Todos los dominios",
    FULL_NAME: "Nombre completo",
    WAIT_MESSAGE: "Espere 5 minutos para propagar los cambios",
    REGULAR: "Regular",
    REFRESH: "Actualizar"
  },
  AUTO_CLOSE: {
    TITLE: "Automatic Logout",
    DESC:
    "No detectamos ninguna actividad durante casi 5 minutos. Permanecer conectado ?",
    TIME_REMAIN: "{sec} segundos para cerrar sesión.",
  },
  HOME: {
    ADMIN_ACCOUNT: "Cuenta Admin",
    STATISTICS: "Estadísticas",
    REMOTE_IP: "IP remota",
    BASIC_ACCOUNTS: "Cuentas básicas",
    ENHANCED_ACCOUNTS: "Cuentas mejoradas",
    DESC1:
      "Para aumentar la seguridad, considere habilitar la Autenticación en 2 fases para esta cuenta",
    DESC2:
      "Simplemente instale una aplicación OTP como <a href='https://www.authy.com/download'> &nbsp; Authy &nbsp; </a> y haga clic en 2FA en el menú.",
    DESC3: "Autenticación de 2 factores no habilitada!",
  },
  TWOFA: {
    AUTHY: "Authy",
    TFAUTH: "Autenticación en dos fases",
    SEL_STATUS: "Seleccionar estado",
    DEC1: "Asegúrese de haber instalado una aplicación OTP, como",
    DEC2: "En el paso 2, tendrá que escanear un Código QR usando la App OTP.",
    DEC3:
    "Cuando esté listo, presione el botón &#39;SIGUIENTE&#39; a continuación.",
    SCAN_DEC1: "Escanee este código QR usando su App OTP.",
    SCAN_DEC2:
      "Una vez que se añada la cuenta, recibirá un código de 6 dígitos",
    SCAN_DEC3: "Introduzca el código en el campo a continuación.",
    SCAN_DEC4:
      "Presione el botón &quot;GUARDAR&quot; a continuación antes de que caduque su código.",
    CHALLENGE: "Desafío",
    DISABLE_TITLE: "Deshabilitar Autenticación en dos fases",
    DDEC1:
      "Para deshabilitar la Autenticación en 2 pasos, inserte el código de su App OTP ( Authy ).",
    DDEC2:
      "Presione el botón &quot;GUARDAR&quot; a continuación antes de que caduque su código. Si esto sucede, inserte nuevamente su código recién generado desde su aplicación OTP (Authy).",
  },
  USERS: {
    QUSAGE: "Cuota y uso",
    EXPORT_RESULTS: "Exportar resultados",
    NEW_USER: "Nuevo usuario",
    DEL_USER: "Borrar usuario",
    ADD_USER: "Agregar usuario",
    UPDATE_USER: "Actualizar usuario",
    PASS_DEC: "Asegúrese de guardar esta contraseña",
    ACCOUNT_TYPE: "Tipo de cuenta",
    ACCOUNT_TYPE_OEX: "Tipo de cuenta OEX",
    NAME_OF_USER: "Nombre de usuario",
    FACTOR_AUTH: "Aut. en 2 fases",
    ONETIME_TITLE: "Contraseña de una vez creada!",
    ONETIME_SUCCESS: "¡Esta contraseña es válida solo por 5 minutos!",
    BASIC: "Básico",
    ENHANCED: "Mejorado",
    NEVER: "Nunca",
    LAST_LOGIN: "Último inicio de sesión",
    BASIC_ACCOUNT: "Cuenta Básica",
    ENHANCED_ACCOUNT: "Cuenta mejorada",
    AUDIT_ACCOUNT: "Audit Account",
    DISABLE_SMTP_ONLY: "Solo SMTP",
    USER_QUOTA: "{num1}GB libre de {num2}GB",
    USER_CONTROL_PANEL: "Panel de control del usuario",
    EXCHANGE: "Conector Exchange",
    EXCHANGE_ACCOUNTS: "Conector Exchange",
    OFFICE365: "Conector externo",
    OFFICE365_ACCOUNTS: "Conector externo",
    MORE_OPTIONS: "Mas Opciones",
    LESS_OPTIONS: "Menos opciones",
    ONLY_LOCAL_SMTP: "Only Local SMTP",
    PERM: {
      ACCESS_HOURS: "Acceda al correo electrónico solo entre horas",
      APP_PASS: "Contraseñas de aplicaciones",
      CAN_ACCESS: "Puede acceder:",
      CHANGE_PASS: "Cambiar la contraseña",
      DELIV_RULES: "Reglas de entrega",
      DISABLED_INFO: "Los usuarios en rojo están deshabilitados o tienen horarios de inicio de sesión restringidos",
      EXTENSIONS: "Extensiones",
      FOLDERS_CLEANUP: "Limpieza de carpetas",
      FORWARDS: "Forwards",
      HOURS_BOTH: "Por favor proporcionar tanto la hora de inicio y hora final",
      HOURS_SIZE: "Hora de inicio debe ser menor de hora final",
      IMAP: "IMAP",
      LAST_LOGINS: "Últimos logins",
      LOCAL_DELIV: "Entrega local",
      PERMISSIONS: "Permisos",
      POP: "POP3",
      SERVICE_PERM: "Permisos de servicio",
      SMTP: "SMTP",
      SPAM_QUARANTINE: "Cuarentena de Spam",
      UNRESTRICTED: "Irrestricto",
      UPDATE_HOURS: "Actualizar horas de acceso",
      UPDATE_PERM: "Actualizar",
      VACATION_MSG: "Mensaje de vacaciones",
      SEND_AS: "Enviar correo como",
      CAN_SEND_AS: "Administrar usuarios que pueden enviar como ",
    },
  },
  MIGRATE: {
    COMPLETE: "Complete",
    DEFAULT: "Default",
    DETAILS: "Migration Details",
    END_TIME: "End Time",
    ETA: "ETA",
    EXIT_MESSAGE: "Exit Message",
    EXIT_STATUS: "Exit Status",
    FOLDER: "Destination Folder",
    LOCAL_MIGRATION: "Local Migration",
    MIGRATE: "Migrate",
    MIGRATE_TO_FOLDER: "Migrate to Folder",
    NEW_MIGRATION: "New Migration",
    PROGRESS: "Progress",
    REFRESHING: "Refreshing every 10s",
    SCHEDULE: "Schedule",
    SECONDS_LEFT: "seconds left",
    STAGE: "Stage",
    START_TIME: "Start Time",
  },
  DOMAINS: {
    NEW_DOMAIN: "Nuevo dominio",
    DEL_DOMAIN: "Eliminar dominio",
    ADD_DOMAIN: "Añadir dominio",
    DOMAIN_NAME: "Nombre de dominio:",
    DOMAIN_TIMEZONE: "Zona horaria de dominio:",
    CATCHALL: "Catchall",
    WEBMAIL: "Webmail",
    EXC_ENABLED: "Exchange habilitado",
    LOCAL_DELIVERY: "Local Delivery",
    AUDIT_ENABLED: "Audit Enabled",
    EDIT_FOOTER: "Editar pie de página",
    FOOTER_DESC:
      "El mensaje que introdujo abajo será anexado a todos los mensajes enviados por todos los usuarios",
    OTHER: "Otro",
    ENHANCED_GROUP_OFFICE: "Mejorado (Group-Office)",
    GROUP_OFFICE: "Grupo de Oficina",
    NO_CATCHALL: "Sin Catchall",
    ACCEPT_DELETE: "Aceptar y eliminar",
    NEW_ALIAS_DOMAIN: "Nuevo Dominio de alias",
    ADD_ALIAS_DOMAIN: "Añadir dominio de alias",
    DEL_ALIAS_DOMAIN: "Eliminar dominio de alias",
    DKIM_SETTINGS: "Configuraciones DKIM",
    ENABLED: "Habilitado",
    HOST: "Registro",
    KEY: "Llave",
    DOMAIN_TYPE: "Tipo de dominio",
    VERIFYING: "Verificación...",
    VERIFY: "Verificar",
    HOSTNAME: "Hostname",
    PLEASE_VERIFY: "Verifica la propiedad del dominio agregando este registro a DNS:",
    OEX: "OEX",
    VERIFY_AGAIN: "Verificar de nuevo",
    HEALTH: "Salud",
    DOMAIN_HEALTH: "Salud del dominio:",
    CHECK_HEALTH_INFO: "Su dominio está actualmente en cola para la verificación. Por favor, permita que el proceso complete el proceso.",
    MX_RECORDS: "Registros MX",
    CAA_RECORD: "Registros CAA",
    WEBMAIL_RECORDS: "Registros Webmail",
    WEBMAIL_SSL: "Webmail SSL",
    AUTODISCOVER_RECORDS: "Registros Autodiscover",
    AUTODISCOVER_SSL: "Autodiscover SSL",
    SPF_RECORDS: "Registros SPF",
    OK: "OK",
    FAIL: "Fallar",
    INSTALLED: "Instalada",
    NOT_INSTALLED: "No instalado",
    FOUND: "Encontrada",
    NOT_FOUND: "No encontrado",
    EXPL_MX: "If not properly set, emails won't be delivered to your domain ",
    EXPL_CAA: "If not properly set, we cannot generate SSL certificates",
    EXPL_WEBMAIL: "If not properly set, webmail access for your domain will be restricted",
    EXPL_WEBMAIL_SSL: "If not properly set, connections to webmail might be insecure",
    EXPL_AUTODISCOVER: "If not properly set, email clients won't auto-configure, causing setup issues",
    EXPL_AUTODISCOVER_SSL: "If not properly set, autodiscover connections will be insecure, risking data exposure",
    EXPL_SPF: "If not properly set, your emails could be marked as spam or rejected",
  },
  ALIASES: {
    NEW_ALIAS: "Nuevo alias",
    ADD_ALIAS: "Añadir alias",
    DEL_ALIAS: "Eliminar alias",
    UPDATE_ALIAS: "Actualización de alias",
    ALIAS: "Alias",
    FORWARDS_TO: "Reenvía a",
    FORWARDS_DESTINATION: "Reenviado destino",
    INTERNAL: "Interno",
    EXTERNAL: "Externo",
  },
  AUDIT: {
    ENTRY: "Entry",
    AUDIT_EMAIL: "Audit email",
    INCOMING: "Incoming",
    OUTGOING: "Outgoing",
    AUDIT_TYPE: "Audit type",
    EMAIL_ADDRESS_DOMAIN: "Email Address or Domain",
    NO_AUDIT_DOMAIN: "No audit domain created. To enable audit for a domain, go to the Domain page.",
    NO_AUDIT_EMAIL: "No audit email created. To add a new Audit type mailbox, go to the User page.",
  },
  LISTS: {
    ADD_LIST: "Añadir lista",
    NEW_LIST: "Nueva lista",
    DEL_LIST: "Eliminar lista",
    ADD_MEMBER: "Añadir miembro",
    MEMBER: "Miembro",
    MEMBER_NAME: "Nombre de miembro",
    LIST_NAME: "Nombre de lista",
    LIST_TYPE: "Tipo de lista",
    DISTRIBUTION_LIST: "Lista de distribución",
    SPAM_RETRAINER: "Retención Correo no deseado",
    NOT_SPAM_RETRAINER: "Retención No-Correo no deseado",
    EXCHNAGE_ACCOUNT: "Cuenta Exchange",
    EMAIL_ADDRESS: "Dirección de correo electrónico",
    EMAIL_ADDRESS_DOMAIN: "Correo electrónico o dominio",
    INTERNAL_MEMBERS: "Miembros internos",
    EXTERNAL_MEMBERS: "Miembros externos",
  },
  SMARTLISTS: {
    ADD_LIST: "Agregar lista inteligente",
    NEW_LIST: "Nueva lista inteligente",
    DEL_LIST: "Eliminar lista inteligente",
    UPDATE_LIST: "Actualizar lista inteligente",
    SPECIFY_MEMBERS: "Especificar miembros",
    ALL_DOMAIN_USERS: "Todos los usuarios del dominio",
    DG_TYPE: "Tipo de lista",
    LIST_PROTECTION: "Seguridad de la lista",
    ONLY_MEMBERS_POST: "Solo los miembros pueden publicar",
    PASSWORD_PROTECTED: "Contraseña",
    NO_PROTECTION: "Sin protección",
    SEND_LABEL: "Enviar una copia del mensaje al remitente",
    MEMBER_MANAGEMENT: "Gestión de miembros",
    CAN_POST: "Puede publicar",
    CAN_RECEIVE: "Recibir mensajes de lista",
    MEMBERS_LIST: "Lista de miembros",
    MEMBERS: "Miembros",
    ALL_MEMBER_POST: "Todos los miembros pueden publicar",
    MY_SUBJECT: "My Subject",
    PASSWORD_TXT:
      "When sending an email, enter this password at the beginning of the subject, in brackets.",
    EX: "EX",
  },
  FILTERING: {
    SETTINGS: {
      FILTER_SETTINGS: "Ajustes del filtro",
      ENABLE_AUTOWHITELIST: "Habilitar lista blanca automática",
      FILTER_SENSITIVITY: "Sensibilidad del filtro",
      KEEP_SPAM_FOR: "Mantener correo no deseado para",
      SEND_SPAM_REPORT: "Enviar informe de correo no deseado",
      SPAM_REPORT_CONTENT: "Contenido del informe de correo no deseado",
      SPAM_REPORT_FORMAT: "Formato de informe de correo no deseado",
      LOGIN_LOCATION_LIMIT: "Límite de ubicación de Inicio de sesión",
      NORMAL_SENSITIVITY: "Sensibilidad normal",
      MOST_SPAM: "Pasa la mayoría del Correo no deseado",
      MOST_MESSAGE: "La mayoría de los mensajes son filtrados",
      ALL_SPAM_MESSAGES: "Todos los mensajes no deseados",
      LATEST_SPAM_MESSAGES: "Últimos mensajes no deseados",
      HTML_TEXT: "HTML y TEXTO",
      HTML_ONLY: "Solo HTML",
      TEXT_ONLY: "Solo TEXTO",
      DONT_SEND: "No enviar",
      EVERY_HOUR: "Cada 1 hora",
      EVERY_HOURS: "Cada {num} horas",
    },
    WHITELIST: {
      IGNORE_SPF: "Ignorar SPF",
      IGNORE_SPF_IP: "Ignorar SPF IP",
      SENDER_IP: "Dirección IP del remitente",
      IMPORT_DEC: "Cargue un archivo en formato CSV, una dirección por línea",
      ADD_WHITELIST: "Añadir lista blanca",
      NEW_WHITELIST: "Nueva lista blanca",
      DEL_WHITELIST: "Eliminar lista blanca",
      IMPORT: "Importar",
      CHOOSE_PLACEHOLDER: "Elija un CSV o suéltelo aquí ...",
      DROP_PLACEHOLDER: "Suelta el archivo aquí ...",
      UPDATE_WHITELIST: "Actualizar lista blanca",
      INCLUDE_USERS_WHITELIST: "Incluir la lista blanca de las usuarios",
      APPLY_TO_HEADERS: "Aplicar a encabezados",
    },
    BLACKLIST: {
      NEW_BLACKLIST: "Nueva lista negra",
      ADD_BLACKLIST: "Añadir lista negra",
      DEL_BLACKLIST: "Eliminar lista negra",
      UPDATE_BLACKLIST: "Actualizar lista negra",
      INCLUDE_USERS_BLACKLIST: "Incluir la lista negra de las usuarios",
    },
    AUTO_WHITELIST: {
      ADDRESS_LIKE: "Dirección como",
      PICK_DATE: "Elija una fecha",
    },
    IPACCESS_LISTS: {
      PREFERENCE: "Preferencia",
      SELECT_IPTYPE: "Seleccione tipo de IP",
    },
  },
  BRANDING: {
    FORM_DESC: "Estos ajustes aplicarán a todos los usuarios del dominio",
    COMPANY_COLOR: "Color de la empresa",
    COMPANY_NAME: "Nombre de la compañía",
    COMPANY_WEBLINK: "Enlace web de la compañía",
    SUPPORT_EMAIL: "Correo electrónico de soporte",
    COMPANY_LOGO: "Logotipo de la compañía",
    DEFAULT_LOGO: "Logotipo predeterminado",
    DEFAULT_LOGO_DESC:
      "Logotipo predeterminado para interfaz de tipo Básico Grupo de Oficina",
    UPDATE_SETTINGS: "Actualizar ajustes",
    PREVIEW: "Vista previa",
    PREVIEW_LOGO: "Vista previa del logotipo",
  },
  SETTINGS: {
    CHANGE_PASSWORD: "Cambiar contraseña",
    CHNAGE_EMAIL: "Cambiar correo electrónico",
    CHANGE_PASSWORD_DESC: "Puedes cambiar tu contraseña",
    SYSTEM_TEXTS: "Textos de sistema",
    QUOTA: "CC de Alerta de cuota",
    NEW_PASSWORD: "Nueva contraseña",
    CONFIRM_PASSWORD: "Confirmar contraseña",
    CURRENT_EMAIL: "Email actual",
    NEW_EMAIL: "Nuevo correo electrónico",
    CHANGE_SYSTEXT: "Cambiar Textos de sistema",
    TEXT_FOR: "Texto para",
    TEXT: "Texto",
    TIMEOUT: "Tiempo de espera del panel de administración",
    MINUTES: "Minutos",
    CHANGE_QUOTA_ALERT: "Cambiar Alerta de cuota",
    QUOTA_ALERT: "Alerta de cuota",
    CHANGE_ADMIN_LOCALE: "Cambiar configuración regional de Admin",
    CHANGE: "Cambiar",
    FORGOT_PASSWORD_MAIL_TEXT:
      "Texto para restablecer contraseña de correo admin",
    FORGOT_PASSWORD_WEB_TEXT: "Texto para restablecer contraseña de web admin",
    QUOTA_WARNING_TEXT: "Texto de advertencia de cuota",
    QUOTA_WARNING_SUBJECT: "Asunto de advertencia de cuota",
    FORGOT_PASSWORD_MAIL_SUBJECT: "Asunto sobre restablecer correo admin",
    PASSWORD_EXPIRY_WARNING_TEXT:
      "Texto de advertencia de expiración de contraseña",
    PASSWORD_EXPIRY_WARNING_SUBJECT:
      "Asunto de advertencia de expiración de contraseña",
    PASSWORD_EXPIRED_WARNING_TEXT:
      "Texto de advertencia de contraseña expirada",
    PASSWORD_EXPIRED_WARNING_SUBJECT:
      "Asunto de advertencia de contraseña expirada",
  },
  LOGS: {
    INCOMING_LOGS: {
      DELIVERED: "Entregado",
      UNDELIVERED: "No entregado",
      DELIVERED_TO: "Entregado a",
      ENVELOPE_FROM: "Sobre de",
      TO: "A",
      SUBJECT: "Asunto",
      IP: "IP",
      SPAM_SCORE: "Puntuación de spam",
      DNSBL: "DNSBL",
      SPF: "SPF",
      DELIVERE: "Entregar",
      WHITELISTED: "Lista blanca",
      WHITELIST_DELIVER: "En Lista blanca y entregado",
      HEADER_FROM: "Encabezado de",
      REJECTED: "Rechazado",
      DETAILS: "Detalles",
      MORE: " & {num} MAS",
    },
    OUTGOING_LOGS: {
      VIEW_MESSAGES: "Ver mensajes",
      SENT_FROM: "Enviado desde",
      SENDER_IP: "IP del emisor",
      SENT_TO: "Enviado a",
      FROM_DATE: "Desde fecha",
      TO_DATE: "Hasta fecha",
      DELIVERED: "Entregado",
      TEMPORARILY_REJECTED: "Rechazado temporalmente",
      PERMANENTLY_REJECTED: "Rechazado permanentemente",
      ALL_MESSAGES: "Todos los mensajes",
      DESC: "Esta interfaz no muestra las entregas al mismo dominio.",
      RECIPIENT_STATUS: "Estado de entrega del destinatario",
      MESSAGE_STATUS: "Estado de entrega de mensaje",
    },
  },
  LAST_LOGINS: {
    START_DATE: "Fecha de inicio",
    END_DATE: "Fecha final",
    NEGATE_CONDITION: "Negar condición",
    PASSWORD_USED: "Contraseña usada",
    SERVICE_USED: "Servicio usado",
    RESET_SEARCH: "Restablecer búsqueda",
    LOCATION: "Ubicación",
    IMAP: "imap",
    POP3: "pop3",
    SMTP: "smtp",
    SIEVE: "sieve",
    LOGIN: "login",
    BLOCKED: "Bloqueada",
  },
  SUB_ADMINS: {
    ACCOUNT_HISTORY: "Historial de cuenta",
    DGLISTS: "Listas inteligentes",
    NO_ACCESS: "Sin acceso",
    VIEW: "Ver",
    ENABLE_DISABLE: "Habilitar / Deshabilitar",
    REMOVE: "Eliminar",
    IMPERSONATE: "Personificar",
    ADD_UPDATE: "Agregar / Actualizar",
    MODIFY: "Modificar",
    ADD_SUBADMIN: "Agregar Sub-Admin",
    DESC: "Los permisos de nivel superior incluyen los anteriores.",
  },
  SECURITY: {
    ACTION:
      "Estos usuarios pueden tener su cuenta comprometida. Cambie su contraseña inmediatamente e indique al usuario que ejecute un análisis antivirus.",
    ADMIN_ACCESS_INFO: "Debes agregar al menos una IP a la lista",
    ALLOW_BUTTON: "Permitir acceso de administrador sin restricciones",
    COUNTRIES: "Países de inicio de sesión distintos",
    ENABLED_COUNTRY: "Habilitado para inicios de sesión de más de {num} países",
    INFO: "Haga clic en el usuario para ver sus últimos inicios de sesión.",
    IP: "IP",
    IP_DESC: "Por qué confía en esta IP: nombre de la ubicación o del servicio.",
    IP_BLOCKED: "La dirección IP está bloqueada",
    IP_NOT_BLOCKED: "La dirección IP no está bloqueada",
    MARK_SAFE: "Marcar como seguro",
    MONITOR_LOGINS: "Supervise los inicios de sesión sospechosos",
    NONE: "No se detectaron incidentes de seguridad en las últimas 24 horas.",
    RESTRICT_BUTTON: "Restringir el acceso de Admin solo a las direcciones IP en la lista",
    RESTRICT_STATUS_ACTIVE: "El acceso de Admin está restringido solo a las direcciones IP en esta lista",
    RESTRICT_STATUS_FREE: "El acceso de Admin no está restringido",
    RESTRICT_WARNING: "Advertencia: asegúrese de que su IP esté en la lista de acceso de IP de administrador. De lo contrario, podría bloquearse.",
    RESTRICT: "Restringir",
    SECURITY_WARNING:
      "Nuevos incidentes de seguridad detectados. Haga clic aquí para consultar la página de seguridad.",
    SENT: "Correos enviados",
    TYPE_DESC: "Elige si deseas marcar como segura la IP o toda la red",
    YOUR_IP: "¡Atención! Su IP actual es {ip} y no está en la Lista de acceso de IP de administrador. Si continúa, ¡se bloqueará!",
    VERIFY_IP: "Verificar si la IP está bloqueada",
  },
  VALIDATION: {
    REQUIRED_FIELD: "Este es un campo obligatorio.",
    DOMAIN_FIELD: "Este es un campo obligatorio y debe ser un dominio válido.",
    USERNAME_FIELD:
      "Este es un campo obligatorio y debe ser un nombre de usuario válido.",
    ALIAS_FIELD: "This is a required field and must be valid alias.",
    EMAIL_FIELD: "Este es un campo obligatorio y de correo electrónico.",
    EMAIL_DOMAIN_FIELD: "Este es un campo de dominio o correo electrónico obligatorio.",
    QUOTA_FIELD: "La cuota disponible es de {num} GB.",
    QUOTA_ZERO: "No queda cuota.",
    REQUIRED_MINLENGTH_FIELD:
      "Este es un campo obligatorio y debe tener al menos {num} caracteres.",
    IDENTICAL_FIELD: "Este es un campo obligatorio y debe ser idéntico.",
    IP_INVALID: "Dirección IP inválida",
    FILL_ALL_FIELDS: "Por favor rellena todos los campos",
    MAX_VALUE: "Este es un campo obligatorio y debe ser inferior a {num}",
    MAX_LETTERS: "Debe tener {num} caracteres como máximo.",
    MIN_LETTERS: "Debe tener al menos {num} caracteres de longitud.",
    REGEX: "Por favor, coincida con el formato aceptado: {rule}",
    NUMBERS: "números",
    LETTERS: "letras",
    LOWERCASE_LETTER: "Debe contener una letra minúscula.",
    UPPERCASE_LETTER: "Debe contener una letra mayúscula.",
    NUMBER_LETTER: "Debe contener un número.",
    SPECIAL_LETTER: "Debe contener un caracter especial.",
  },
  AUTH: {
    GENERAL: {
      OR: "O",
      SUBMIT_BUTTON: "Enviar",
      NO_ACCOUNT: "¿No tienes una cuenta?",
      SIGNUP_BUTTON: "Regístrate",
      FORGOT_BUTTON: "Se te olvidó tu contraseña",
      BACK_BUTTON: "atrás",
      PRIVACY: "Intimidad",
      LEGAL: "Legal",
      CONTACT: "Contacto",
      WELCOME_ADMIN: "Bienvenido al Panel de control del Administrador!",
    },
    LOGIN: {
      TITLE: "Cuenta de Ingreso",
      BUTTON: "Registrarse",
      DESC1:
        "Bienvenido a nuestro nuevo Panel de Administración! Para acceder a la versión heredada, visite: ",
      DESC2:
        "Si tiene habilitada la Autenticación en dos fases, añada su código de 6 dígitos al final de su contraseña",
      DESC3:
        "Obtenga el código de la aplicación de autenticación Twofa en su dispositivo móvil y complételo a continuación.",
      DESC4:
        "Ya no es necesario agregar el código de autenticación de dos factores a la contraseña.",
      TFA_INVALID: "Código no válido o el código ha caducado",
      TFA: "Tu codigo:",
    },
    FORGOT: {
      TITLE: "¿Se te olvidó tu contraseña?",
      SEND: "Enviar",
      DESC:
        "Si su nombre de usuario es el mismo que su dirección de correo electrónico, introdúzcalo dos veces",
    },
    RESET_PASSWORD: {
      TITLE: "Restableciendo tu contraseña...",
      DESC: "Haga clic en el botón y generaremos una nueva contraseña",
      GENERATE_NEW_PASSWORD: "Generar nueva contraseña",
      GO_LOGIN: "Ir a la página de inicio de sesión",
    },
    REGISTER: {
      TITLE: "Regístrate",
      DESC: "Ingrese sus datos para crear su cuenta",
      SUCCESS: "Su cuenta ha sido registrada con éxito.",
    },
    INPUT: {
      EMAIL: "Email",
      PASSWORD: "Contraseña",
      USERNAME: "Nombre de usuario",
    },
    SIGN_OUT: "Desconectar",
    RESET: "Restablecer",
    VALIDATION: {
      EMAIL:
        "Se requiere correo electrónico y una dirección de correo electrónico válida.",
      PASSWORD: "Se requiere contraseña.",
      USERNAME: "Se requiere nombre de usuario.",
    },
  },
  ZULIP:{
    IS_ADMIN: "Is Admin",
    UPDATE: "Update Zulip User",
    ERROR_STATUS: "Error on status update",
    ZULIP_ACCOUNTS: "Zulip Accounts",
  },
};
